import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Tracking.css";
import { ImSearch } from "react-icons/im";
import { ImCross } from "react-icons/im";
import { FaBitbucket, FaPeopleCarry } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import axios from "axios";
import { ServerAddress } from "../../../constants/ServerAddress";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import toTitleCase from "../../../titleCase/TitleCase";

const TrackingOrder = () => {
  const location = useLocation();
  const [order_id, setorder_id] = useState("");
  const [search, setsearch] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const gr_no = location.search.replace(/[?]/g, "");
  const [get_orders, setget_orders] = useState([]);

  const get_order_data = (gr) => {
    axios
      .get(ServerAddress + "booking/get_order_status/?gr_no=" + gr)
      .then((response) => {
        console.log("response data tracking...".response)
        const gr_info = response.data[0];
        console.log("gr_ifo....", gr_info)
        const last = gr_info[gr_info.length - 1];
        if (response.data[0].length > 0) {
          let last_ele = response.data;
          let last_data_s = last_ele.map((item) => item.push(false));
          setget_orders(response.data);

          setsearch(true);
        } else {
          alert("GR Not Found");
        }
      })
      .catch((err) => {
        alert(`Error Occur in Get , ${err}`);
      });
  };

  useEffect(() => {
    if (gr_no !== "") {
      setorder_id(gr_no);
      get_order_data(gr_no);
    }
  }, [gr_no]);

  console.log("get order data", get_orders)

  return (
    <>
      <div className="background">
        <PageTitle page="Tracking" />
        <nav className="nav1">
          <Link className="nav_link" to="/signin">
            Login
          </Link>
          <a
            className="nav_link"
            href="https://www.etechcube.com/"
            target="_blank"
          >
            Website
          </a>
        </nav>
        <div className="main">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (order_id.length > 5) {
                  get_order_data(order_id);
                } else {
                  alert("Please Enter a valid GR Number");
                }
                return false;
              }}
            >
              <div className="search">
                <input
                  type="text"
                  name="Search"
                  className="input1"
                  placeholder=" Enter GR Number"
                  onChange={(val) => {
                    setorder_id(val.target.value);
                  }}
                  value={order_id}
                />{" "}
                <button className="search_btn" type="submit">
                  <i>
                    {" "}
                    <ImSearch />
                  </i>
                </button>
              </div>
            </form>
          </div>

          <div>
            {get_orders.map((item, index) => {
              console.log("TO_item====",item);
              const last_ele = item[item.length - 2];
              console.log("LAST_ITEM====",last_ele);
              return (
                <div key={index}>
                  {search ? (
                    <div
                      className="container"
                      style={{ paddingBottom: "20px" }}
                    >
                      <div
                        className="t-card-body t-card"
                        style={{ borderRadius: "10px" }}
                      >
                        <h5
                          style={{
                            fontSize: "21px",
                            borderRadius: "10px",
                            borderRadius: "10px 10px 0 0",
                            textAlign: "left",
                          }}
                          className="card-title header"
                        >
                          Tracking
                        </h5>
                        <div
                          className="card-text"
                          style={{
                            margin: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <b style={{ fontSize: "15px" }}>GR Number :</b>{" "}
                            {last_ele?.gr_no}
                          </div>
                          {last_ele?.issue_notreceived?.length !== 0 &&
                            last_ele?.status !== "CONSIGNMENT DELIVERED" && (
                              <div>
                                <b style={{ fontSize: "15px" }}>
                                  Not Received Packets :
                                </b>{" "}
                                {last_ele?.issue_notreceived?.length}
                              </div>
                            )}
                        </div>
                        <div
                          // className="row"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "20px",
                          }}
                        >
                          <div className="col">
                            {" "}
                            <strong style={{ fontSize: "19px" }}>
                              Origin:
                            </strong>
                            <br />
                            {toTitleCase(last_ele?.origin_state)}
                            {", "} {toTitleCase(last_ele?.origin_city)}
                            {", "} {toTitleCase(last_ele?.origin_pincode)}
                          </div>

                          <div className="col">
                            {" "}
                            <strong style={{ fontSize: "19px" }}>
                              Destination:
                            </strong>{" "}
                            <br />
                            {toTitleCase(last_ele?.destination_state)}
                            {", "} {toTitleCase(last_ele?.destination_city)}
                            {", "} {toTitleCase(last_ele?.destination_pincode)}
                            {/* {item[0].order_detail[0].consignee_city__city}{" "} */}
                          </div>
                          <div className="col">
                            {" "}
                            <strong style={{ fontSize: "19px" }}>
                              Status:
                            </strong>
                            <br />
                            {toTitleCase(last_ele?.status)}
                          </div>
                          <div className="col">
                            {" "}
                            <strong style={{ fontSize: "19px" }}>
                              Current Location:
                            </strong>{" "}
                            <br />
                            {toTitleCase(last_ele?.current_state)}
                            {", "}
                            {toTitleCase(last_ele?.current_city)}
                            {", "} {last_ele?.current_pincode}
                          </div>
                        </div>
                        <div style={{ padding: "15px" }}>
                          <div className="track">
                            {(last_ele?.status === "CONSIGNMENT ORDER RECEIVED" || last_ele?.status === "CONSIGNMENT FURTHER CONNECTED") ? (
                              <>
                                {" "}
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBitbucket />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Order Received
                                  </span>{" "}
                                </div>
                                <div className="step">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaPeopleCarry />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Ready For Dispatch
                                  </span>{" "}
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-home"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Delivery Receipt
                                  </span>{" "}
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-truck"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Out for Delivery
                                  </span>
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaBox />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">Delivered</span>
                                </div>
                              </>
                            ) : null}

                            {(last_ele?.status === "CONSIGNMENT READY FOR DISPATCH" || last_ele?.status === "CONSIGNMENT IN TRANSIT") ? (
                              <>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBitbucket />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Order Received
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaPeopleCarry />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Ready For Dispatch
                                  </span>{" "}
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-home"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Delivery Receipt
                                  </span>{" "}
                                </div>

                                <div className="step">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-truck"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Out for Delivery
                                  </span>
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaBox />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text"> Delivered</span>
                                </div>
                              </>
                            ) : null}

                            {(last_ele?.status === "CONSIGNMENT DELIVERY RECEIPT") ? (
                              <>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBitbucket />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Order Received
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaPeopleCarry />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Ready For Dispatch
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-home"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Delivery Receipt
                                  </span>{" "}
                                </div>
                                <div className="step">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-truck"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Out for Delivery
                                  </span>
                                </div>
                                <div className="step ">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBox />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text"> Delivered</span>
                                </div>
                              </>
                            ) : null}

                            {last_ele?.status === "CONSIGNMENT OUT FOR DELIVERY" ? (
                              <>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBitbucket />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Order Received
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaPeopleCarry />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Ready For Dispatch
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-home"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Delivery Receipt
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-truck"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    Consignment Out for Delivery
                                  </span>
                                </div>
                                <div className="step">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBox />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text"> Delivered</span>
                                </div>
                              </>
                            ) : null}

                            {last_ele?.status === "CONSIGNMENT DELIVERED" ||
                              last_ele?.status === "SHORT DELIVERED" ? (
                              <>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBitbucket />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Order Received
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      <FaPeopleCarry />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Ready For Dispatch
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-home"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Delivery Receipt
                                  </span>{" "}
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i className="fa fa-truck"></i>{" "}
                                  </span>{" "}
                                  <span className="text">
                                    {" "}
                                    Consignment Out for Delivery
                                  </span>
                                </div>
                                <div className="step active">
                                  {" "}
                                  <span className="icon">
                                    {" "}
                                    <i>
                                      {" "}
                                      <FaBox />
                                    </i>{" "}
                                  </span>{" "}
                                  <span className="text"> Delivered</span>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <button
                          className="more_btn"
                          onClick={() => {
                            let last = item[item.length - 1];
                            // setnext(true);
                            item[item.length - 1] = true;
                            setrefresh(!refresh);
                          }}
                          type="button"
                        >
                          More Details{" "}
                        </button>
                      </div>
                      {/* </div> */}
                    </div>
                  ) : null}

                  {item[item.length - 1] === true ? (
                    <div
                      className="t-card"
                      style={{ width: "100%", borderRadius: "10px" }}
                    >
                      <div
                        className="t-card-body"
                        style={{ borderRadius: "10px" }}
                      >
                        <h5
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            fontSize: "21px",
                            borderRadius: "10px 10px 0 0",
                          }}
                          className="card-title header"
                        >
                          Tracking / History
                          <div
                            onClick={() => {
                              // setnext(false);
                              item[item.length - 1] = false;
                              setrefresh(!refresh);
                            }}
                            key={index}
                          >
                            {" "}
                            <ImCross style={{ fontSize: "15px" }} />
                          </div>
                        </h5>
                        {/* <div className="card-text" style={{ margin: "5px" }}> */}
                        {/* <strong>Docket No. :</strong>{" "} */}
                        {/* {last_ele.docket_no}
                        </div> */}
                        <div>
                          <table
                            className="table table-bordered"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              // whiteSpace: "nowrap"
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Branch</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.length === 0 ? (
                                <tr>
                                  <td>No Data Found</td>
                                </tr>
                              ) : (
                                item.map((item1, index) => {
                                  if (item.length - 1 !== index) {
                                    const [date, timeWithOffset] =
                                      item1.created_at.split("T");
                                    const [time] = timeWithOffset.split(".");
                                    const d_update_date = date;
                                    const update_time = time.substring(0, 8);
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {toTitleCase(item1.status)}
                                          <br />
                                          <span
                                            style={{
                                              color: "gray",
                                              fontSize: "12.5px",
                                            }}
                                          >
                                            {toTitleCase(item1.transit_status)}
                                          </span>
                                        </td>
                                        <td>{d_update_date}</td>
                                        <td>{update_time}</td>
                                        <td>
                                          {toTitleCase(
                                            item1.current_branch_name,
                                          )}

                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: "410px", padding: "10px", color: "white" }}>
          <strong>Copyright © 2022.</strong> All rights reserved.
        </div>
      </div>
    </>
  );
};
export default TrackingOrder;
