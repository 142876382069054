import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gr_number: [],
  search_gr: false,
};

export const orderTrackingSlice = createSlice({
  name: "Tracking",
  initialState,
  reducers: {
    setGrNumber: (state, action) => {
      state.gr_number = action.payload;
    },
    setSearchGr: (state, action) => {
      state.search_gr = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGrNumber, setSearchGr } = orderTrackingSlice.actions;

export default orderTrackingSlice.reducer;
