import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cur_order_id: "",
  cur_ref_id: "",
  cur_order_gr_no: "",
  cur_order_ref_no: "",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCurOrderId: (state, action) => {
      state.cur_order_id = action.payload;
    },
    setCurRefId: (state, action) => {
      state.cur_ref_id = action.payload;
    },
    setCurOrderGRNo: (state, action) => {
      state.cur_order_gr_no = action.payload;
    },
    setCurOrderRefNo: (state, action) => {
      state.cur_order_ref_no = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurOrderId,setCurRefId, setCurOrderGRNo ,setCurOrderRefNo} = orderSlice.actions;

export default orderSlice.reducer;
