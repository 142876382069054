import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modified_name: "",
  filteredItems: [], // Add filteredItems state
};

export const ItemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setmodified_name: (state, action) => {
      state.modified_name = action.payload;
    },
    updateFilteredItems: (state, action) => { // Define updateFilteredItems reducer
      state.filteredItems = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setmodified_name, updateFilteredItems } = ItemSlice.actions;

export default ItemSlice.reducer;
