import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsGear } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";

import {
  setAlertType,
  setDataExist,
  setShowAlert,
} from "../../store/alert/Alert";
import { setNavToggle } from "../../store/dataList/DataList";
import ProfileMenu from "../TopbarDropdown/ProfileMenu";
import { BsSearch } from "react-icons/bs";
import {
  setGrNumber,
  setSearchGr,
} from "../../store/orderTracking/OrderTracking";
import "../../../src/components/dashboardComponents/card/HeaderCard.css"
import { setManifestTab, setRunsheetTab } from "../../store/parentFilter/ParentFilter";



const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


 
  const [link_clicked, setlink_clicked] = useState(false);
      const userpermission = useSelector(
    (state) => state.authentication.userpermission
  );
  const user = useSelector((state) => state.authentication.userdetails);
  const [manifists, setManifists] = useState(false);
  const [booking, setBooking] = useState(false);
  const [profile, setProfile] = useState(false);
  const [runsheet, setRunsheet] = useState(false);
  const [runsheetdata, setrunsheetdata] = useState([]);
  const [status, setstatus] = useState(false);
  const [billing, setbilling] = useState(false);
  const show_alert = useSelector((state) => state.alert.show_alert);
  const data_exist = useSelector((state) => state.alert.data_exist);
  const alert_type = useSelector((state) => state.alert.alert_type);


  useEffect(() => {
    if (show_alert) {
      setTimeout(() => {
        dispatch(setShowAlert(false));
      }, 4000);
    }
  }, [show_alert]);

  const [sidebarActive, setSidebarActive] = useState(true);
  useEffect(() => {
    dispatch(setNavToggle(false));
  }, []);

  const handleClk = () => {
    setSidebarActive(!sidebarActive);
    dispatch(setNavToggle(sidebarActive));
  };
  const nav_toggle = useSelector((state) => state.datalist.nav_toggle);

  //Tracking Docket
  const [gr_number_entry, setgr_number_entry] = useState([]);

  useEffect(() => {
    if (gr_number_entry === "") {
      dispatch(setGrNumber([]));
      dispatch(setSearchGr(false));
    }
  }, [gr_number_entry]);

  return (
    <header>
      {show_alert ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert
            style={{
              position: "absolute",
              right: nav_toggle ? "200px" : "65px",
              top: "2vh",
              width: "75vw",
              zIndex: 200,
            }}
            color={alert_type}
            toggle={() => {
              dispatch(setDataExist(""));
              dispatch(setAlertType(""));
              dispatch(setShowAlert(""));
            }}
          >
            {data_exist}
          </Alert>
        </div>
      ) : null}
      <div
        style={{ width: "100%" }}
        className={
          sidebarActive ? "margin-left-20rem navbar-header" : "navbar-header"
        }
      >
        <div className="header_res">



          <div className="res_section">
            <div>
              <button
                className={"hamburgerButton"}
                type="button"
                onClick={() => handleClk()}
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
            {(user.is_superuser) && (
              <nav>
                <button className="stylebtn dropdown"

                  onBlur={() => {
                    if (link_clicked) {
                      setlink_clicked(false);
                    } else {
                      setBooking(false);
                    }
                  }}
                  onClick={() => setBooking(!booking)}
                >
                  <p>Bookings <span style={{ fontSize: "12px" }}>▼</span></p>
                  <div
                    className="dropdownMenu"
                    id={booking ? "active" : ""}
                    style={{
                      position: "absolute",
                      top: "39px",
                      left: "0px",
                      fontSize: "15.5px",
                    }}
                  >
                    <Link
                      onMouseDown={() => setlink_clicked(true)}
                      to="/booking"
                    >
                      New Order
                    </Link>
                    <Link
                      onMouseDown={() => setlink_clicked(true)}
                      to="/booking/bookinglist"
                    >
                      Orders List
                    </Link>
                    <Link
                      onMouseDown={() => setlink_clicked(true)}
                      to="/booking/docketIssue"
                    > 
                      Docket Issue
                    </Link>
                  </div>
                </button>
                <button className="stylebtn dropdown" style={{backgroundColor:"#CECE5A"}}
                  onBlur={() => {
                    if (link_clicked) {
                      setlink_clicked(false);
                    } else {
                      setManifists(false);
                    }
                  }}
                  onClick={() => setManifists(!manifists)}
                >
                  <p>Manifests <span style={{ fontSize: "12px" }}>▼</span></p>
                  <div
                    className="dropdownMenu"
                    id={manifists ? "active" : ""}
                    style={{
                      position: "absolute",
                      top: "39px",
                      left: "0px",
                      fontSize: "13.5px",
                      textWrap:"nowrap"
                    }}
                  >
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setManifestTab(1));
                      }}
                      to="/manifest/pendingfordispatch"
                    >
                      Pending For Dispatch
                    </Link>
                    
                    <Link
                      onMouseDown={() =>{ setlink_clicked(true)
                        dispatch(setManifestTab(2));
                      }}
                      to="/manifest/addheadmanifest"
                    >
                      Main Manifest
                    </Link>
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setManifestTab(3));
                      }}
                      to="/manifest/pendingtodepart"
                    >
                      Pending To Depart
                    </Link>
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setManifestTab(4));
                      }}
                      to="/manifest/allmanifest"
                    >
                      All Manifest
                    </Link>
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setManifestTab(5));
                      }}
                      to="/manifest/incomingmanifest"
                    >
                      Incoming Manifest
                    </Link>
                  </div>
                </button>

                <button className="stylebtn dropdown" style={{ backgroundColor: "#FEEFAD" }}
                  onBlur={() => {
                    {
                      if (link_clicked) {
                        setlink_clicked(false);
                      } else {
                        setRunsheet(false);
                      }
                    }
                  }}
                  onClick={() => setRunsheet(!runsheet)}
                >
                  <p>Runsheet <span style={{ fontSize: "12px" }}>▼</span></p>
                  <div
                    className="dropdownMenu"
                    id={runsheet ? "active" : ""}
                    style={{
                      position: "absolute",
                      top: "39px",
                      left: "0px",
                      fontSize: "15.5px",
                    }}
                  >
                    <Link
                      onMouseDown={() =>{ setlink_clicked(true)
                        dispatch(setRunsheetTab(1));
                      }}
                      to="/runsheet/deliveryReceipt/DeliveryReceipt"
                    >
                      Delivery Receipt
                    </Link>
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setRunsheetTab(2));
                      }}
                      to="/runsheet/pendingdelivery"
                    >
                      Pending Delivery
                    </Link>
                    <Link
                      onMouseDown={() => {setlink_clicked(true)
                        dispatch(setRunsheetTab(3));
                      }}
                      to="/runsheet/allrunsheet"
                      state={{ runsheet: runsheetdata }}
                    >
                      All Runsheet
                    </Link>
                    <Link
                      onMouseDown={() => setlink_clicked(true)}
                      to="/runsheet/podUpload"
                    >
                      Pod Upload
                    </Link>
                  </div>
                </button> 

                <button className="stylebtn dropdown" style={{ backgroundColor: "#68D2E8" }}>
                  <Link
                    onMouseDown={() => setlink_clicked(true)}
                    to="/track/trackingPage/TrackingOrder"
                  >
                    <p>Track <span style={{ fontSize: "12px" }}>▼</span></p>
                  </Link>
                </button>
              </nav>
            )}
          </div>

          <div className="res_search">
            <nav style={{ cursor: "pointer" }}>
              <div>
                <input className="search_bar"

                  type="search"
                  placeholder="Enter GR Number"
                  value={gr_number_entry}
                  onChange={(val) => {
                    setgr_number_entry(val.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    if (gr_number_entry == "" || gr_number_entry.length < 1) {
                      alert("Please Enter a valid GR Number");
                    } else {
                      dispatch(setSearchGr(true));
                      dispatch(setGrNumber(gr_number_entry));
                    }
                  }}
                  style={{
                    border: "2px solid #b6afaf",
                    borderRadius: "0px 14px 14px 0px",
                    background: " #e1d6d6",
                    borderLeft: "none",
                    height: " 28px",
                    width: "28px",
                  }}
                >
                  {gr_number_entry !== "" && gr_number_entry.length > 5 ? (
                    <a
                      href={`/track/trackingPage/TrackingOrder?${gr_number_entry}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsSearch />
                    </a>
                  ) : (
                    <BsSearch />
                  )}
                </button>
              </div>
              {/* <NotificationDropdown /> */}
              <ProfileMenu />
              
              <BsGear
                className="rotate"
                style={{ fontSize: "1.25rem", margin: "0 1rem" }}
              />
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
